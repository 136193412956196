<template>
	<div ref="echartLine" style="width:100%;height:100%;">
	</div>
</template>
<script>
	import echarts from 'echarts'
	export default {
		name: 'echartLine',
		props: {
			echartLineData: {
				type: Array,
				default: []
			},
			lineXAxisData: {
				type: Array,
				default: []
			},
			lineDateData: {
				type: Array,
				default: []
			}

		},
		data() {
			return {
				series: [],
				legend: [],
			}
		},
		mounted() {
			this.initData()
			this.getEchart();
			
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		watch: {
			echartLineData(n, o) {
				this.initData()
				this.$nextTick(() => {
					this.getEchart();
				});
			},
			lineXAxisData(n, o) {
				this.$nextTick(() => {
					this.getEchart();
				});
			},
		},
		methods: {
			resize(){
				this.myChart.resize()
			},
			initData() {
				this.legend = []
				this.series = []
				this.echartLineData.forEach(t => {
					if (!t.value) {
						t.value = 0
					}
					this.legend.push(t.name)
					let series = {
						name: t.name,
						type: 'line',
						data: t.value,
						smooth: true,
					}
					this.series.push(series)
				})
			},
			getEchart() {
				this.myChart = echarts.init(this.$refs.echartLine)
				let option = {
					legend: {
						data: this.legend
					},
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let date = '';
							let value = '';
							let result = ''
							for (let i = 0, l = params.length; i < l; i++) {
								if (this.lineDateData.length) {
									this.lineDateData.forEach((tt, ii) => {
										if (params[i].dataIndex == ii) {
											date = params[i].axisValue + '(' + tt + ')'
											value += '<br/>' + params[i].seriesName + ' : ' + params[i].data;
										}
									})
								} else {
									date = params[i].axisValue
									value += '<br/>' + params[i].seriesName + ' : ' + params[i].data;
								}
							}
							result = date + value
							return result;
						}
					},
					color: ['#60acfc', '#ff7c7c', '#32d3eb', '#feb64d', '#5bc49f', '#9287e7', '#FFEE58', '#f44336', '#E91E63',
						'#CDDC39'
					],
					grid: {
						left: '1%',
						top: '10%',
						right: '3%',
						bottom: '4%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: this.lineXAxisData,
					},
					yAxis: {
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						}
					},
					series: this.series
				};
				this.myChart.setOption(option);
			}
		}
	}
</script>

<template>
	<div class="main-container" v-loading='loading'>
		<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime'></statistics-time>
		<div class="overview">
			<div class="title">流量总览</div>
			<div class="interview">
				<div class="left">
					浏览访问
				</div>
				<div class="right">
					<div class="item">
						<span class="lable">访客数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UV" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.UV}}</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.UV,PrevFlowOverview.UV)"></svg-icon>
							<span>{{commentDatafun(CurrentFlowOverview.UV,PrevFlowOverview.UV)}}%</span>
						</div>
					</div>
					<div class="item" v-if="timeType!=0">
						<span class="lable">新访客数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.NewUV" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.NewUV}}</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.NewUV,PrevFlowOverview.NewUV)"></svg-icon>
							<span>{{commentDatafun(CurrentFlowOverview.NewUV,PrevFlowOverview.NewUV)}}%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">浏览量</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.PV" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.PV}}</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.PV,PrevFlowOverview.PV)"></svg-icon>
							<span>{{commentDatafun(CurrentFlowOverview.PV,PrevFlowOverview.PV)}}%</span>
						</div>
					</div>
					<div class="item" v-if="timeType!=0">
						<span class="lable">平均停留时长（秒）</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.StayTimePerCapita" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.StayTimePerCapita}}</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.StayTimePerCapita,PrevFlowOverview.StayTimePerCapita)"></svg-icon>
							<span>{{commentDatafun(CurrentFlowOverview.StayTimePerCapita,PrevFlowOverview.StayTimePerCapita)}}%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">人均浏览量</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.PVPerCapita" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.PVPerCapita}}</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.PVPerCapita,PrevFlowOverview.PVPerCapita)"></svg-icon>
							<span>{{commentDatafun(CurrentFlowOverview.PVPerCapita,PrevFlowOverview.PVPerCapita)}}%</span>
						</div>
					</div>
					<div class="item" v-if="timeType!=0">
						<span class="lable">跳失率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.MissRate" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.MissRate}}%</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.MissRate,PrevFlowOverview.MissRate)"></svg-icon>
							<span>{{commentRatefun(CurrentFlowOverview.MissRate,PrevFlowOverview.MissRate)}}%</span>
						</div>
					</div>
				</div>
			</div>
			<div class="interview flow">
				<div class="left">
					流量转化
				</div>
				<div class="right">
					<div class="item">
						<span class="lable">访问-加购转化率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UVAndAddToCartMemberCountConversionRate" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.UVAndAddToCartMemberCountConversionRate}}%</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.UVAndAddToCartMemberCountConversionRate,PrevFlowOverview.UVAndAddToCartMemberCountConversionRate)"></svg-icon>
							<span >{{commentRatefun(CurrentFlowOverview.UVAndAddToCartMemberCountConversionRate,PrevFlowOverview.UVAndAddToCartMemberCountConversionRate)}}%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">访问-下单转化率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UVAndSubmitOrderMemberCountConversionRate" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.UVAndSubmitOrderMemberCountConversionRate }}%</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.UVAndSubmitOrderMemberCountConversionRate,PrevFlowOverview.UVAndSubmitOrderMemberCountConversionRate)"></svg-icon>
							<span>{{commentRatefun(CurrentFlowOverview.UVAndSubmitOrderMemberCountConversionRate,PrevFlowOverview.UVAndSubmitOrderMemberCountConversionRate)}}%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">访问-支付转化率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UVAndPayOrderMemberCountConversionRate" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.UVAndPayOrderMemberCountConversionRate }}%</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.UVAndPayOrderMemberCountConversionRate,PrevFlowOverview.UVAndPayOrderMemberCountConversionRate)"></svg-icon>
							<span>{{commentRatefun(CurrentFlowOverview.UVAndPayOrderMemberCountConversionRate,PrevFlowOverview.UVAndPayOrderMemberCountConversionRate)}}%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">UV价值</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UVValue" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentFlowOverview.UVValue}}</span>
						<div class="compare" :class="{'compare-hidden':timeType==0}">较前一{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentFlowOverview.UVValue,PrevFlowOverview.UVValue)"></svg-icon>
							<span>{{commentDatafun(CurrentFlowOverview.UVValue,PrevFlowOverview.UVValue)}}%</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="trend">
			<div class="title">流量趋势</div>
			<div class="radio">
				<div>
					<span style="line-height:40px;">流量访问：</span>
					<br>
					<span style="line-height:40px;">流量转化：</span>
				</div>
				<el-radio-group v-model="trendRadioValue"  @change='handleTrendRadio'>
					<el-radio :label="0" class="radio-item">访客数</el-radio>
					<el-radio :label="1" class="radio-item" v-show="timeType!=0">新访客数</el-radio>
					<el-radio :label="2" class="radio-item">浏览量</el-radio>
					<el-radio :label="3" class="radio-item" v-show="timeType!=0">平均停留时长（秒）</el-radio>
					<el-radio :label="4" class="radio-item">人均浏览量</el-radio>
					<el-radio :label="5" class="radio-item" v-show="timeType!=0">跳失率</el-radio>
					<br>
					<el-radio :label="6" class="radio-item">访问-加购转化率</el-radio>
					<el-radio :label="7" class="radio-item">访问-下单转化率</el-radio>
					<el-radio :label="8" class="radio-item">访问-支付转化率</el-radio>
					<el-radio :label="9" class="radio-item">UV价值</el-radio>
				</el-radio-group>
			</div>
			<div class="echart" style="width:100%;height:400px;">
				<echart-line :echartLineData='echartData1'  :lineXAxisData='lineXAxisData1' :lineDateData='lineDateData1'></echart-line>
				<!-- <flow-echart :echartData='echartData' :legendValue='legendValue' :legends='legends'></flow-echart> -->
			</div>
		</div>

		<div class="structure">
			<div class="title">
				<span>流量来源类型构成</span>
				<el-button v-if="timeType==0||timeType==5||timeType==6" class="right" type="text" @click="handleDownload">下载</el-button>
			</div>
			<div class="check">
				<div class="check-box">
					<div>
						<span style="line-height:40px;">浏览访问：</span>
						<br>
						<span style="line-height:40px;">浏览转化：</span>
					</div>
					<div style="width: 944px;;">
						<div class="item">
							<el-checkbox :label="1"  v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(1)">访客数（占比）</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item"  v-if="timeType!=0">
							<el-checkbox :label="2"  v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(2)">新访客数</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.NewUV" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<br>
						<div class="item" v-if="timeType!=0">
							<el-checkbox :label="3" v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(3)">UV价值</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UVValue" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item">
							<el-checkbox :label="4" v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(4)">下单人数</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.SubmitOrderMemberCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item">
							<el-checkbox :label="5" v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(5)">访问-加购转化率</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UVAndAddToCartMemberCountConversionRate" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item">
							<el-checkbox :label="6" v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(6)">访问-下单转化率</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UVAndSubmitOrderMemberCountConversionRate" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item">
							<el-checkbox :label="7" v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(7)">访问-支付转化率</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UVAndPayOrderMemberCountConversionRate" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item">
							<el-checkbox :label="8" v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(8)">下单金额</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.SubmitOrderMoney" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item">
							<el-checkbox :label="9" v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(9)">支付金额</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.PayOrderMoney" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item">
							<el-checkbox :label="10" v-model="checks"  @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(10)">支付人数</el-checkbox>
							<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.PayOrderMemberCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
					</div>
				</div>
				<div style='margin-left:68px;margin-top:10px;color:#666;' v-if="timeType!=0">最多只能同时选择5个指标</div>
			</div>
			<div class="remark">
				<span>列表中已隐藏所选时间范围内无数据的流量来源。</span>
			</div>
			<div class="table">
				<el-table  @sort-change="sortChange" ref="flowAnalysisTable" :data="proportionList" :default-sort="defaultSort" style="width: 100%">
					<el-table-column :key="1" prop="RefererValue" label="来源类型"></el-table-column>
					<el-table-column :key="2" v-if="checks.includes(1)" prop="UV" sortable label="访客数（占比）">
						<template slot-scope="scope">
							<span>{{scope.row.UV}}</span>
							<span style="margin-left:10px;color:#999;">{{scope.row.Proportion}}%</span>
						</template>
					</el-table-column>
					<el-table-column :key="3" v-if="checks.includes(2)" prop="NewUV" sortable label="新访客数"></el-table-column>
					<el-table-column :key="4" v-if="checks.includes(3)" prop="UVValue" sortable label="UV价值"></el-table-column>
					<el-table-column :key="5" v-if="checks.includes(4)" prop="SubmitOrderMemberCount" sortable label="下单人数"></el-table-column>
					<el-table-column :key="6" v-if="checks.includes(5)" prop="UVAndAddToCartMemberCountConversionRate" sortable label="访问-加购转化率">
						<template slot-scope="scope">
							{{scope.row.UVAndAddToCartMemberCountConversionRate}}%
						</template>
					</el-table-column>
					<el-table-column :key="7" v-if="checks.includes(6)" prop="UVAndSubmitOrderMemberCountConversionRate" sortable label="访问-下单转化率">
						<template slot-scope="scope">
							{{scope.row.UVAndSubmitOrderMemberCountConversionRate}}%
						</template>
					</el-table-column>
					<el-table-column :key="8" v-if="checks.includes(7)" prop="UVAndPayOrderMemberCountConversionRate" sortable label="访问-支付转化率">
						<template slot-scope="scope">
							{{scope.row.UVAndPayOrderMemberCountConversionRate}}%
						</template>
					</el-table-column>
					<el-table-column :key="6" v-if="checks.includes(8)" prop="SubmitOrderMoney" sortable label="下单金额"></el-table-column>
					<el-table-column :key="7" v-if="checks.includes(9)" prop="PayOrderMoney" sortable label="支付金额"></el-table-column>
					<el-table-column :key="8" v-if="checks.includes(10)" prop="PayOrderMemberCount" sortable label="支付人数"></el-table-column>
					<el-table-column :key="9" v-if="timeType!=0" label="操作">
						<template slot-scope="scope">
							<el-button type="text" @click="handleTrendShow(scope.row)">趋势</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination style="margin-top:20px;float:right;" v-if="page.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 20, 30, 40]" :page-size="page.size"
				 :total="page.total" layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="流量来源趋势" width="1000px" :visible.sync="flowRefererTrendShow">
			<div class="echart-box" style="height:400px;">
				<echart-line :echartLineData='echartLineData2' :lineXAxisData='lineXAxisData2' :lineDateData='lineDateData2'></echart-line>
			</div>
    </el-dialog>
	</div>
</template>
<script>
	import statisticsTime from './StatisticsTime'
	import echartLine from './flowAnalysisEchart'
	import apiList from '@/api/other'
	export default {
		components: {
			echartLine,
			statisticsTime
		},
		data() {
			return {
				loading:false,
				flowRefererTrendShow:false,
				startLineTime: '',
				endLineTime: '',
				startDate: '',
				endDate: '',
				timeType: '',
				unShowType: [6,7, 8, 9, 10],
				currentKeyword: '日',
				timeKeywords: ['','日', '周', '月', '天', '天', '天', '天'],
				trendRadioValue: 0,
				echartData: {
					x: [],
					y: []
				},
				lineXAxisData1:[],
				lineDateData1:[],
				echartData1:[],
				legendsNum:0,
				tooltip:{
					UV:'统计筛选时间内，所有商城页面被访问的人数，人数去重，1人访问多次计1人',
					NewUV:'筛选时间内，所有商城访客中，此前从未访问过商城的客户为新访客',
					PV:'统计筛选时间内，商城所有页面被访问的次数，1人访问多次计多次',
					StayTimePerCapita:'统计筛选时间内，商城所有访客停留时长总和/访客数',
					PVPerCapita:'统计筛选时间内，商城总浏览量/访客数',
					MissRate:'统计筛选时间内，只访问了一个页面就离开商城的访客数/商城总访客数',
					UVAndAddToCartMemberCountConversionRate:'统计筛选时间内，将商品添加至购物车的人数/商城访客数；添加至购物车的人数与商城访客数均去重',
					UVAndSubmitOrderMemberCountConversionRate:'统计筛选时间内，在商城内下单的人数/商城访客数；下单人数与商城访客数均去重',
					UVAndPayOrderMemberCountConversionRate:'统计筛选时间内，商城内支付成功的人数/商城访客数；支付成功的人数与商城访客数均去重',
					UVValue:'统计筛选时间内，支付成功的金额／商城访客数',
				},
				checkTooltip:{
						UV: '统计筛选时间内，该流量来源的访客数/所有流量来源的访客数之和',
						NewUV: '筛选时间内，所有商城访客中，此前从未访问过商城的客户，且通过该来源进入商城的访客',
						UVValue: '统计筛选时间内，支付金额/访客数；访客数去重',
						SubmitOrderMemberCount: '统计筛选时间内，通过该来源进入商城并且下单成功的人数',
						UVAndAddToCartMemberCountConversionRate: '统计筛选时间内，通过该来源进入商城，并且将商品添加至购物车的人数/该来源访客数；添加至购物车的人数与商城访客数均去重',
						UVAndSubmitOrderMemberCountConversionRate: '统计筛选时间内，通过该来源进入商城，并且在商城内下单的人数/商城访客数；下单人数与商城访客数均去重',
						UVAndPayOrderMemberCountConversionRate: '统计筛选时间内，通过该来源进入商城，并且支付成功的人数/商城访客数；支付成功的人数与商城访客数均去重',
						SubmitOrderMoney: '统计筛选时间内，通过该来源进入商城，成功下单的金额（不剔除已关闭或已退款订单，包含运费）',
						PayOrderMoney: '统计筛选时间内，通过该来源进入商城，成功支付的金额（不剔除已关闭或已退款订单，包含运费）',
						PayOrderMemberCount: '统计筛选时间内，通过该来源进入商城，成功支付的人数；1人支付成功多次计1人',
				},
				echartDataGroup:[
						{	id:0,
							name:'访客数',
							label:'UV',
							value:[]
						},
						{	id:1,
							name:'新访客数',
							label:'NewUV',
							value:[]
						},
						{	id:2,
							name:'浏览量',
							label:'PV',
							value:[]
						},
						{	id:3,
							name:'平均停留时长（秒）',
							label:'StayTimePerCapita',
							value:[]
						},
						{	id:4,
							name:'人均浏览量',
							label:'PVPerCapita',
							value:[]
						},
						{	id:5,
							name:'跳失率',
							label:'MissRate',
							value:[]
						},
						{	id:6,
							name:'访问-加购转化率',
							label:'UVAndAddToCartMemberCountConversionRate',
							value:[]
						},
						{	id:7,
							name:'访问-下单转化率',
							label:'UVAndSubmitOrderMemberCountConversionRate',
							value:[]
						},
						{	id:8,
							name:'访问-支付转化率',
							label:'UVAndPayOrderMemberCountConversionRate',
							value:[]
						},
						{	id:9,
							name:'UV价值',
							label:'UVValue',
							value:[]
						},
						
					],
				tableData: [],
				proportionList:[],
				checks: [1, 3, 5],
				checksList: [{
						id:1,
						name: '访客数（占比）',
						props: 'UV',
						value:[]
					},
					{
						id:2,
						name: '新访客',
						props: 'NewUV',
						value:[]
					},
					{
						id:3,
						name: 'UV价值',
						props: 'UVValue',
						value:[]
					},
					{
						id:4,
						name: '下单人数',
						props: 'SubmitOrderMemberCount',
						value:[]
					},
					{
						id:5,
						name: '访问-加购转化率',
						props: 'UVAndAddToCartMemberCountConversionRate',
						value:[]
					},
					{
						id:6,
						name: '访问-下单转化率',
						props: 'UVAndSubmitOrderMemberCountConversionRate',
						value:[]
					},
					{
						id:7,
						name: '访问-支付转化率',
						props: 'UVAndPayOrderMemberCountConversionRate',
						value:[]
					},
					{
						id:8,
						name: '下单金额',
						props: 'SubmitOrderMoney',
						value:[]
					},
					{
						id:9,
						name: '支付金额',
						props: 'PayOrderMoney',
						value:[]
					},
					{
						id:10,
						name: '支付人数',
						props: 'PayOrderMemberCount',
						value:[]
					}
				],
				defaultSort: {
					prop: 'Visitors',
					order: 'descending'
				},
				page: {
					size: 20,
					current: 1,
					total: 0
				},
				OrderBy:'',
				IsAsc:'',
				lineXAxisData2:[],
				lineDateData2:[],
				echartLineData2:[],
				CurrentFlowOverview: {},
				PrevFlowOverview: {
					AddToCartMemberCount: 0,
					MissRate: 0,
					NewUV: 0,
					OnePageUV: 0,
					PV: 0,
					PVPerCapita: 0,
					PayOrderMemberCount: 0,
					PayOrderMoney: 0,
					StatisHour: 0,
					StayTime: 0,
					StayTimePerCapita: 0,
					SubmitOrderMemberCount: 0,
					UV: 0,
					UVAndAddToCartMemberCountConversionRate: 0,
					UVAndPayOrderMemberCountConversionRate: 0,
					UVAndSubmitOrderMemberCountConversionRate: 0,
					UVValue: 0,
				},
				groupDate:[],
				tableGroupDate:[]
			}
		},
		created() {
		},
		methods: {
			getRangeTime(startDate, endDate, timeType, startLineTime, endLineTime, groupDate) {
				this.lineXAxisData1 = groupDate
				this.startLineTime = startLineTime
				this.endLineTime = endLineTime
				this.groupDate = groupDate
				this.startDate = startDate
				this.endDate = endDate
				this.timeType = timeType
				this.currentKeyword = this.timeKeywords[timeType]
				if (this.timeType == 0) {
					this.checks = [1, 4]
				}
				this.getData()
				this.getTableData()
			},
			// 公用计算的方法
			commentDatafun(CurrentData, PrevData) {
				if(PrevData==0){
					if(CurrentData==0){
						return 0
					}else{
						return 100
					}
				}else{
					return (Math.abs(CurrentData - PrevData) / PrevData* 100).toFixed(2) 
				}
			},
			// 公用计算的方法
			commentRatefun(CurrentData, PrevData) {
				return (Math.abs(CurrentData - PrevData)).toFixed(2) 
			},
			// 公用的箭头方法
			commentArrowsfun(CurrentData, PrevData) {
				let upOrDown = ""
				if (CurrentData != PrevData) {
					if (CurrentData > PrevData) {
						upOrDown='gorise'
					}else{
						upOrDown='decline'
					}
				} else {
					upOrDown = ''
				}
				return upOrDown
			},
			//初始化
			async getData() {
				try {
					let data1 = {
						TimeType: this.timeType,
						StartDate: this.startDate,
						EndDate: this.endDate,
						ClientType: 1
					}
					let result = await apiList.dataStatisFlowOverview(data1);
					if(result.Result.PrevFlowOverview){
						this.PrevFlowOverview = result.Result.PrevFlowOverview
					}
					this.CurrentFlowOverview = result.Result.CurrentFlowOverview

					let data2 = {
						TimeType: this.timeType,
						StartDate: this.startLineTime,
						EndDate: this.endLineTime,
						ClientType: 1
					}

					let resultTrend = await apiList.dataStatisFlowTrend(data2)
					this.echartDataGroup.forEach((t)=>{
						let item = []
						resultTrend.Result.forEach(tt=>{
							Object.keys(tt).forEach(ttt=>{
								if(t.label == ttt){
									item.push(tt[ttt])  
								}
							})
						})
						t.value = item
					})

					this.lineDateData1 = []

					if(this.timeType == 2){
						resultTrend.Result.forEach(t=>{
							let start = t.StatisStartDate.trim().split(" ")[0]
							let end = t.StatisEndDate.trim().split(" ")[0]
							let item = start+'~'+end
							this.lineDateData1.push(item)
						})
					}
					

					this.echartData1 = []
					
					this.echartDataGroup.forEach(t=>{
						if(t.id == this.trendRadioValue){
							this.echartData1.push(t)
						}
					})

					if(this.timeType==0){
						this.lineXAxisData1=[]
						resultTrend.Result.forEach(t=>{
							this.lineXAxisData1.push(t.StatisHour)
						})
					}
				} catch (e) {
					console.log(e)
				}
			},
			async getTableData(){
				try {
					let data = {
						TimeType: this.timeType,
						StartDate: this.startDate,
						EndDate: this.endDate,
						ClientType: 1
					}
					let resultReferer = await apiList.dataStatisFlowReferer(data)

					this.tableData = resultReferer.Result
					let allUV = 0
					this.tableData.forEach((t)=>{
						allUV +=t.UV
					})
					this.tableData.forEach(t=>{
						let Proportion = 0
						Proportion = (allUV==0)? 0 :(100*t.UV/allUV).toFixed(2)
						t.Proportion = Proportion
					})
					this.paginationTable()
				} catch(e){
					console.log('e',e)
				}
			},
			sortChange(column, prop, order) {
				if (column.order == "ascending") {
					this.IsAsc = 1
					this.OrderBy = column.prop
				} else if(column.order == "descending") {
					this.IsAsc = 2
					this.OrderBy = column.prop
				} else {
					this.IsAsc = 3
					this.OrderBy = ''
				}
				this.page.current = 1;
				this.paginationTable()
			},
			
			//分页
			paginationTable() {
				let tableData = []
				if(this.IsAsc!=3){
					tableData = this.tableData.sort((a,b)=>{
						if(this.IsAsc==1){
							return a[this.OrderBy]-b[this.OrderBy]
						}else if(this.IsAsc==2){
							return b[this.OrderBy]-a[this.OrderBy]
						}
					})
				}else{
					tableData = this.tableData
				}
				this.page.total = tableData.length;
				let start = (this.page.current - 1) * this.page.size
				let end = start + this.page.size
				let pagination = tableData.slice(start, end);
				this.proportionList = pagination
				this.$forceUpdate()
			},
			//下载
			handleDownload() {

			},
			//查看趋势
			handleTrendShow(val) {
				this.flowRefererTrendShow = true
				this.getRecentDays()
				this.getRefererTrendData(val.Referer)
			},
			async getRefererTrendData(Referer){
				let data = {
					Referer: Referer,
					ClientType : 1
				}
				let result = await apiList.dataStatisFlowRefererTrend(data)
				this.checksList.forEach((t)=>{
					let item = []
					result.Result.forEach(tt=>{
						Object.keys(tt).forEach(ttt=>{//遍历一个对象的属性
							if(t.props == ttt){
								item.push(tt[ttt])  
							}
						})
					})
					t.value = item
				})
				
				this.lineXAxisData2 = this.tableGroupDate
				this.echartLineData2=[]
				this.checks.forEach(t=>{
					this.checksList.forEach(tt=>{
						if(t==tt.id){
							this.echartLineData2.push(tt)
						}
					})
				})
			},
				// 遍历每一天
			dealDateRange(start,end){
				var result = [];
				var beginDay = start.split("-");
				var endDay = end.split("-");
				var diffDay = new Date();
				var dateList = new Array;
				var i = 0;
				diffDay.setDate(beginDay[2]);
				diffDay.setMonth(beginDay[1]-1);
				diffDay.setFullYear(beginDay[0]);
				result.push(start);
				while(i == 0){
					var countDay = diffDay.getTime() + 24 * 60 * 60 * 1000;
					diffDay.setTime(countDay);
					dateList[2] = diffDay.getDate();
					dateList[1] = diffDay.getMonth() + 1;
					dateList[0] = diffDay.getFullYear();
					if(String(dateList[1]).length == 1){dateList[1] = "0"+dateList[1]}
					if(String(dateList[2]).length == 1){dateList[2] = "0"+dateList[2]}
					result.push(dateList[0]+"-"+dateList[1]+"-"+dateList[2]);
					if(dateList[0] == endDay[0] && dateList[1] == endDay[1] && dateList[2] == endDay[2]){ i = 1;
					}
				}
				this.tableGroupDate = result
			},
			//获取近30天
			getRecentDays(){
				let time = new Date()
				let endLineTime =  this.formatDate(new Date(time - 3600 * 24 * 1000))
				let startLineTime = this.formatDate(new Date(time - 3600 * 24 * 1000 * 30))
				this.dealDateRange(startLineTime,endLineTime)
			},
			//格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
			formatDate(date) {
				date = new Date(date)
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
			},
			formatTen(num) {
				return num > 9 ? (num + "") : ("0" + num);
			},
			getEchartLineData(){
				this.echartLineData2 = []
				this.checks.filter(t=>{
					this.checksList.forEach(tt=>{
					if(tt.id==t){
						return tt
					}
					})
				})
			},
			// change流量趋势radios
			handleTrendRadio(e) {
				this.legendsNum = this.trendRadioValue
				this.echartData1 = []
				this.echartDataGroup.forEach(t=>{
					if(t.id == this.trendRadioValue){
						this.echartData1.push(t)
					}
				})
				this.$nextTick(()=>{
					this.$forceUpdate()
				})
			},
			// //check能量来源构成
			handleStructureCheck() {
			},
			handleSizeChange(val) {
				this.page.size = val
				this.getTableData()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.getTableData()
			}
		}
	}
</script>
<style lang="less" scoped>
	.main-container {
		.title {
			font-size: 20px;
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			.right {
				float: right;
				margin-right: 5px;
			}
		}

		.icon {
			color: #999;
			margin-left: 5px;
			cursor: pointer;
		}

		.top {
			padding: 10px 15px;
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: #fff;
		}

		.overview {
			margin-top: 10px;
			padding: 15px;
			background-color: #fff;

			.interview {
				background-color: rgb(225, 238, 251);
				overflow: hidden;
				display: flex;
				flex-direction: row;

				.left {
					width: 80px;
					background-color: #409EFF;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					font-size: 14px;
				}

				.right {
					height: 100%;
					color: #606266;
					width: calc(100% - 80px);
					align-items: center;

					.item {
						padding: 25px;
						display: inline-block;
						width: 25%;
					}

					.item:nth-child(5n) {
						word-wrap: break-word;
						word-break: normal;
					}

					.lable {
						line-height: 20px;
						font-size: 12px;
					}

					.icon {
						font-size: 14px;
						color: #999;
						margin-left: 5px;
						cursor: pointer;
					}

					.num {
						line-height: 30px;
						font-size: 18px;
					}

					.compare {
						line-height: 20px;
						font-size: 12px;
					}

					.compare-hidden {
						display: none;
					}
				}
			}

			.flow {
				margin-top: 5px;
				background-color: rgb(225, 251, 231);

				.left {
					background-color: rgb(83, 172, 108);
				}
			}
		}

		.trend {
			margin-top: 10px;
			padding: 15px;
			background-color: #fff;
			font-size: 14px;

			.radio {
				background-color: #eee;
				padding: 15px;
				display: flex;
				flex-direction: row;
				.radio-item{
					width:130px;
					line-height:40px;
				}
			}

			.echart {
				width: 100%;
				overflow: hidden;
				margin-top: 20px;
			}
		}

		.structure {
			font-size: 14px;
			margin-top: 10px;
			padding: 15px;
			background-color: #fff;

			.check {
				background-color: #eee;
				padding: 15px;
				// display: flex;
				// flex-direction: row;
				.check-box {
					display: flex;
					flex-direction: row;
				}

				.check-label {
					width: 70px;
				}
				.item {
					display: inline-block;
					width: 180px;
					
					line-height:40px;
					// margin-bottom: 10px;
				}

				::v-deep .el-checkbox {
					margin-right: 0 !important;
				}
			}

			.table {
				overflow: hidden;
			}
		}
		

		.remark {
			padding: 15px 0;
			font-size: 14px;
			line-height: 25px;
		}
	}
</style>
